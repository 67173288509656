import React, { useState, useEffect } from "react";
import axios from "axios";
import ManualLotCreator from "./ManualLotCreator";
import BulkLotCreator from "./BulkLotCreator";
import Results from "./Results"; // Import the Results component

export default function CombinedLotCreator() {
  const [view, setView] = useState(""); // "manual" or "bulk"
  const [podCreated, setPodCreated] = useState(false);
  const [podName, setPodName] = useState("");
  const [existingPods, setExistingPods] = useState([]);
  const [selectedPod, setSelectedPod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysisResults, setAnalysisResults] = useState([]);

  const userId = localStorage.getItem("userId") || "defaultUser";

  useEffect(() => {
    // Fetch existing pods for the user
    const fetchPods = async () => {
      try {
        const response = await axios.get(
          `https://v2backend-dot-listalot-440122.uc.r.appspot.com/get-user-pods?userId=${userId}`
        );
        setExistingPods(response.data.pods || []);
      } catch (err) {
        console.error("Failed to fetch pods:", err.message);
      }
    };

    fetchPods();
  }, [userId]);

  const handleCreatePod = async () => {
    if (!podName.trim()) {
      alert("Please enter a valid Pod Name.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/create-pod", {
        podName,
        userId,
        lots: [],
      });
      localStorage.setItem("podId", podName);
      alert(`Pod created successfully: ${response.data.podId}`);
      const newPod = {
        podId: response.data.podId,
        podTitle: podName,
        lots: [],
      };
      setSelectedPod(newPod);
      setPodCreated(true);
    } catch (err) {
      setError("Failed to create pod. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPod = async (e) => {
    const podId = e.target.value;
    const foundPod = existingPods.find((pod) => pod.podId === podId);

    if (foundPod) {
      setSelectedPod(foundPod);
      localStorage.setItem("podId", foundPod.podId);
      setPodCreated(true);

      // If the pod status is completed, fetch analysis results
      if (foundPod.status === "completed") {
        try {
          const response = await axios.get(
            `https://v2backend-dot-listalot-440122.uc.r.appspot.com/get-analysis-lots?userId=${userId}&analysisId=${foundPod.podId}`
          );
          
          setAnalysisResults(response.data.lots || []);
        } catch (err) {
          console.error("Failed to fetch analysis results:", err.message);
        }
      }
    } else {
      setSelectedPod(null);
      setPodCreated(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {!podCreated ? (
        <div className="max-w-lg mx-auto bg-white rounded p-6 shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center">
            Manage or Create Pod
          </h1>
          <div className="mb-6">
            <label className="block font-medium mb-2">
              Manage Existing Pods
            </label>
            <select
              value={selectedPod?.podId || ""}
              onChange={handleSelectPod}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select a pod</option>
              {existingPods.map((pod) => (
                <option key={pod.podId} value={pod.podId}>
                  {pod.podId}
                </option>
              ))}
            </select>
          </div>
          <hr className="my-4" />
          <h2 className="text-xl font-bold mb-4">Or Create a New Pod</h2>
          <div className="mb-4">
            <label className="block font-medium mb-2">Pod Name</label>
            <input
              type="text"
              value={podName}
              onChange={(e) => setPodName(e.target.value)}
              placeholder="Enter pod name"
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button
            onClick={handleCreatePod}
            disabled={loading}
            className={`px-6 py-2 rounded shadow-md w-full ${loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 text-white"
              }`}
          >
            {loading ? "Creating Pod..." : "Create New Pod"}
          </button>
        </div>
      ) : selectedPod?.status === "completed" && analysisResults.length > 0 ? (
        <Results
          lots={analysisResults}
          onUpdate={(updatedLots) => setAnalysisResults(updatedLots)}
        />
      ) : (
        <div className="max-w-4xl mx-auto bg-white rounded p-4 shadow-md">
          <h1 className="text-2xl font-bold mb-4">Lot Management</h1>
          {selectedPod && selectedPod.lots && selectedPod.lots.length > 0 ? (
            <ManualLotCreator
              podName={selectedPod.podId}
              userId={userId}
              lots={selectedPod.lots}
              currentTone={selectedPod.tone}
            />
          ) : (
            <div>
              <label className="block font-medium mb-2">
                Select Creation Method
              </label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="creatorType"
                    value="manual"
                    checked={view === "manual"}
                    onChange={(e) => setView(e.target.value)}
                    className="mr-2"
                  />
                  Manual
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="creatorType"
                    value="bulk"
                    checked={view === "bulk"}
                    onChange={(e) => setView(e.target.value)}
                    className="mr-2"
                  />
                  Bulk
                </label>
              </div>
              {view === "manual" && (
                <ManualLotCreator
                  podName={selectedPod.podId}
                  userId={userId}
                  lots={selectedPod.lots}
                  currentTone={selectedPod.tone}
                />
              )}
              {view === "bulk" && (
                <BulkLotCreator
                  podName={selectedPod.podId}
                  userId={userId}
                  currentTone={selectedPod.tone}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}