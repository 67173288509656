import React, { useState } from "react";
import Papa from "papaparse";

const BarcodeLookup = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [originalFields, setOriginalFields] = useState([]);
  const [newFields, setNewFields] = useState([]);
  const [selectedSearchFields, setSelectedSearchFields] = useState([]);
  const [searchOptions] = useState(["barcode_number", "asin", "sku"]);
  const [barcodeFields] = useState([
    "age_group",
    "asin",
    "barcode_formats",
    "barcode_number",
    "brand",
    "category",
    "color",
    "contributors",
    "description",
    "energy_efficiency_class",
    "features",
    "format",
    "gender",
    "height",
    "images",
    "ingredients",
    "last_update",
    "length",
    "lotNo",
    "manufacturer",
    "material",
    "model",
    "mpn",
    "multipack",
    "nutrition_facts",
    "pattern",
    "release_date",
    "reviews",
    "size",
    "stores",
    "title",
    "weight",
    "width",
  ]);
  const [validationError, setValidationError] = useState("");

  // Handle CSV Upload
  const handleCsvChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const headers = results.meta.fields || [];
        setCsvHeaders(headers);
        setOriginalFields(headers); // Initialize with original fields
        const initialMapping = {};
        headers.forEach((header) => {
          initialMapping[header] = ""; // No mapping initially
        });
        setFieldMapping(initialMapping);
        setNewFields([]); // Reset new fields
        setValidationError("");
      },
      error: (error) => {
        console.error("Error parsing CSV:", error.message);
        setValidationError("Failed to parse CSV file. Please try again.");
      },
    });
  };

  // Handle Field Mapping
  const handleFieldMappingChange = (field, mappedField) => {
    setFieldMapping((prevMapping) => ({
      ...prevMapping,
      [field]: mappedField,
    }));
  };

  // Handle Remove Original Field
  const handleRemoveOriginalField = (field) => {
    setOriginalFields((prev) => prev.filter((f) => f !== field));
    setFieldMapping((prev) => {
      const newMapping = { ...prev };
      delete newMapping[field];
      return newMapping;
    });
  };

  // Handle Add New Field
  const handleAddNewField = (field) => {
    if (!newFields.includes(field)) {
      setNewFields((prev) => [...prev, field]);
    }
  };

  // Handle Remove New Field
  const handleRemoveNewField = (field) => {
    setNewFields((prev) => prev.filter((f) => f !== field));
  };

  // Handle Search Field Selection
  const handleSearchFieldToggle = (field) => {
    setSelectedSearchFields((prev) =>
      prev.includes(field)
        ? prev.filter((f) => f !== field)
        : [...prev, field]
    );
  };

  const handleSubmit = async () => {
    if (!csvFile) {
      alert("Please upload a CSV file before submitting.");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("file", csvFile); // CSV file
    formData.append("userId", localStorage.getItem("userId")); // Replace with actual user ID
    formData.append("searchFields", JSON.stringify(selectedSearchFields)); // Selected search fields

    // Combine original and new fields into selected fields for the backend
    const selectedFields = originalFields.concat(newFields);

    // Map original fields to their barcode field mappings
    const mappedFields = {};
    originalFields.forEach((field) => {
      if (fieldMapping[field]) {
        mappedFields[field] = fieldMapping[field]; // Map original to barcode field
      }
    });

    formData.append("selectedFields", JSON.stringify(selectedFields)); // All selected fields
    formData.append("mappedFields", JSON.stringify(mappedFields)); // Include mapped fields as key-value pairs
    formData.append("newBarcodeFields", JSON.stringify(newFields)); // Newly added barcode fields
    console.log(selectedSearchFields, selectedFields, mappedFields, newFields)
    try {
      const response = await fetch("https://devbackend.listornot.com/process-csv-barcode", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to process barcode lookup.");
      }

      const data = await response.json();
      alert("CSV processed successfully!");

      console.log("Download URL:", data.downloadUrl);
      console.log("Not Found Rows:", data.notFoundRows);
    } catch (error) {
      console.error("Error submitting data:", error.message);
      alert("Failed to process CSV. Please try again.");
    }
  };

  return (
    <div className="p-4 md:p-8 bg-white rounded-lg shadow-lg space-y-6 max-w-4xl mx-auto">
      <h2 className="text-xl font-semibold text-gray-700">Barcode Lookup</h2>

 
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-700">Upload CSV</h3>
        <input
          type="file"
          accept=".csv"
          onChange={handleCsvChange}
          className="p-2 border rounded w-full max-w-md"
        />
        {validationError && (
          <p className="text-red-500 text-sm">{validationError}</p>
        )}
      </div>


      <div>
        <h3 className="text-lg font-medium text-gray-700">Select Search Fields</h3>
        <div className="flex flex-wrap gap-4 mt-2">
          {searchOptions.map((field) => (
            <button
              key={field}
              onClick={() => handleSearchFieldToggle(field)}
              className={`px-4 py-2 ${selectedSearchFields.includes(field) ? "bg-blue-500" : "bg-gray-300"
                } text-white rounded hover:bg-blue-600 transition`}
            >
              {field}
            </button>
          ))}
        </div>
        {selectedSearchFields.length > 0 && (
          <p className="text-gray-500 mt-2">
            Selected: {selectedSearchFields.join(", ")}
          </p>
        )}
      </div>

     
      {originalFields.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-gray-700">
            Original Fields
          </h3>
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Field</th>
                <th className="border border-gray-300 px-4 py-2">Map to</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {originalFields.map((field) => (
                <tr key={field}>
                  <td className="border border-gray-300 px-4 py-2">{field}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <select
                      value={fieldMapping[field] || ""}
                      onChange={(e) =>
                        handleFieldMappingChange(field, e.target.value)
                      }
                      className="p-2 border rounded w-full"
                    >
                      <option value="">Select a field</option>
                      {barcodeFields.map((barcodeField) => (
                        <option key={barcodeField} value={barcodeField}>
                          {barcodeField}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <button
                      onClick={() => handleRemoveOriginalField(field)}
                      className="px-2 py-1 bg-red-500 text-white rounded"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {newFields.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-gray-700">
            New Fields
          </h3>
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Field</th>
                <th className="border border-gray-300 px-4 py-2">Map to</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {newFields.map((field) => (
                <tr key={field}>
                  <td className="border border-gray-300 px-4 py-2">{field}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <select
                      value={field}
                      disabled
                      className="p-2 border rounded w-full bg-gray-200"
                    >
                      <option>{field}</option>
                    </select>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <button
                      onClick={() => handleRemoveNewField(field)}
                      className="px-2 py-1 bg-red-500 text-white rounded"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      <div>
        <h3 className="text-lg font-medium text-gray-700">
          Add New Barcode Fields
        </h3>
        <div className="grid grid-cols-4 gap-4 mt-4">
          {barcodeFields
            .filter(
              (field) =>
                !originalFields.includes(field) &&
                !newFields.includes(field) &&
                !Object.values(fieldMapping).includes(field) // Exclude already mapped fields
            )
            .map((field) => (
              <div key={field} className="flex items-center space-x-2">
                <button
                  onClick={() => handleAddNewField(field)}
                  className="px-2 py-1 bg-green-500 text-white rounded"
                >
                  +
                </button>
                <span>{field}</span>
              </div>
            ))}
        </div>
      </div>

 
      <button
        onClick={handleSubmit}
        disabled={csvHeaders.length === 0}
        className={`px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition disabled:opacity-50`}
      >
        Submit Mapping
      </button>
    </div>
  );
};

export default BarcodeLookup;