import React, { useRef } from "react";
import Webcam from "react-webcam";
import Resizer from "react-image-file-resizer";

export default function CameraCapture({ onCapture, onClose }) {
  const webcamRef = useRef(null);

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  };

  const capturePhoto = async () => {
    if (!webcamRef.current) return;
    const screenshot = webcamRef.current.getScreenshot();
    const response = await fetch(screenshot);
    const blob = await response.blob();
    const file = new File([blob], `capture-${Date.now()}.jpg`, { type: "image/jpeg" });
    const resized = await resizeImage(file);
    onCapture(resized);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg flex flex-col items-center space-y-4">
        <h3 className="text-lg font-semibold">Camera Capture</h3>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className="border border-gray-300 rounded"
        />
        <div className="space-x-2">
          <button onClick={capturePhoto} className="px-4 py-2 bg-blue-600 text-white rounded">
            Capture
          </button>
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}