import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Resizer from "react-image-file-resizer";
import CameraCapture from "./CameraCapture";
import axios from "axios";

export default function BulkLotCreator({ currentTone }) {
  const [tone, setTone] = useState("");
  const [images, setImages] = useState([]);
  const [lots, setLots] = useState([]);
  const [lotSize, setLotSize] = useState("");
  const [mode, setMode] = useState("");
  const [splitIndices, setSplitIndices] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentBatch, setCurrentBatch] = useState("");
  const toneOptions = ["Casual", "Formal", "Technical"];
  const batchSize = 50;

  const podId = localStorage.getItem("podId");
  const userId = localStorage.getItem("userId");

  const resizeImage = (file, index, total) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        70,
        0,
        (uri) => {
          const percent = Math.round(((index + 1) / total) * 100);
          setProgress(percent);
          resolve(uri);
        },
        "file"
      );
    });

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;

    setLoading(true);
    setProgress(0);
    try {
      const resizedFiles = await Promise.all(
        files.map((file, index) => resizeImage(file, index, files.length))
      );
      setImages(resizedFiles);
      setLots([]);
      setSplitIndices([]);
    } catch {
      alert("Failed to resize images.");
    } finally {
      setLoading(false);
    }
  };

  const createFixedLots = () => {
    if (!lotSize || Number(lotSize) <= 0) return;
    const size = Number(lotSize);
    const result = [];
    for (let i = 0; i < images.length; i += size) {
      result.push({
        id: `${result.length + 1}`,
        description: "",
        images: images.slice(i, i + size),
      });
    }
    setLots(result);
  };

  const toggleSplit = (index) => {
    setSplitIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const createSplitLots = () => {
    const sorted = [...splitIndices].sort((a, b) => a - b);
    const result = [];
    let start = 0;
    for (const splitIndex of sorted) {
      result.push({
        id: `${result.length + 1}`,
        description: "",
        images: images.slice(start, splitIndex),
      });
      start = splitIndex;
    }
    result.push({
      id: `${result.length + 1}`,
      description: "",
      images: images.slice(start),
    });
    setLots(result);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const uploadBatch = async (batch, batchIndex) => {
    const formData = new FormData();
    formData.append("podId", podId);
    formData.append("userId", userId);
    formData.append("tone", tone);

    formData.append(
      "lots",
      JSON.stringify(
        batch.map((lot) => ({
          id: lot.id,
          description: lot.description,
          images: [],
        }))
      )
    );

    batch.forEach((lot) => {
      lot.images.forEach((img, index) => {
        formData.append(`images_${lot.id}_${index}`, img);
      });
    });

    try {
      const response = await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/update-pod", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        console.log(`Batch ${batchIndex + 1} uploaded successfully.`);
      }
    } catch (error) {
      console.error(`Error uploading batch ${batchIndex + 1}:`, error.message);
    }
  };

  const handleUpdatePod = async () => {
    if (!lots.length) {
      alert("No lots to update.");
      return;
    }

    setLoading(true);
    setProgress(0);

    try {
      const batches = chunkArray(lots, batchSize);

      for (let i = 0; i < batches.length; i++) {
        setCurrentBatch(`Processing batch ${i + 1} of ${batches.length}`);
        await uploadBatch(batches[i], i);

        const progressPercentage = Math.round(((i + 1) / batches.length) * 100);
        setProgress(progressPercentage);
      }

      alert("All lots updated successfully.");
    } catch (error) {
      console.error("Error updating lots:", error.message);
      alert("Failed to update some lots. Check the console for details.");
    } finally {
      setLoading(false);
    }
  };
  const handleAnalyze = () => {
    alert("Analyze clicked.");
  };


  return (
    <div className="bg-gray-100 p-6 rounded shadow-md space-y-6">
      <h2 className="text-2xl font-bold text-center">Bulk Lot Creator</h2>

      {/* Action Buttons */}
      <div className="flex justify-center space-x-4">
        <button onClick={handleUpdatePod} className="px-4 py-2 bg-indigo-600 text-white rounded">
          Update Pod
        </button>
        <button onClick={handleAnalyze} className="px-4 py-2 bg-green-600 text-white rounded">
          Analyze
        </button>
    
      </div>

      {/* Progress Bar */}
      {loading && (
        <div className="mt-4 bg-white p-4 rounded shadow">
          <p className="text-center text-gray-600">{currentBatch}</p>
          <p className="text-center text-gray-600">{progress}% Completed</p>
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
              ></div>
            </div>
          </div>
        </div>
      )}

      <div>
        <label className="block font-medium mb-2">AI Tone</label>
        <select
          value={tone}
          onChange={(e) => setTone(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Select Tone</option>
          <option value="Casual">Casual</option>
          <option value="Formal">Formal</option>
          <option value="Technical">Technical</option>
        </select>
        <div className="mt-2 p-2 bg-white border border-gray-300 rounded">
          Previous Tone: {currentTone || "None"}
        </div>
      </div>

      {/* Image Upload */}
      <div>
        <label className="block font-medium mb-2">Select Images</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="block w-full p-2 border border-gray-300 rounded"
        />
        <Button
          variant="contained"
          startIcon={<CameraAltIcon />}
          sx={{ mt: 2 }}
          onClick={() => setShowCamera(true)}
        >
          Add from Camera
        </Button>
      </div>

      {/* Images Preview */}
      {images.length > 0 && (
        <div className="grid grid-cols-3 gap-4 mt-4">
          {images.map((img, index) => (
            <div key={index} className="relative">
              <img
                src={URL.createObjectURL(img)}
                alt=""
                className="w-[250px] h-[250px] object-cover border border-gray-300 rounded"
              />
              <IconButton
                onClick={() => {
                  const updatedImages = [...images];
                  updatedImages.splice(index, 1);
                  setImages(updatedImages);
                }}
                size="small"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 15,
                  backgroundColor: "rgba(255, 0, 0, 0.7)",
                  color: "white",
                  "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.9)" },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
        </div>
      )}

      {/* Lot Management */}
      <div className="mt-6">
        <h4 className="font-semibold mb-2">Select Mode</h4>
        <div className="flex items-center space-x-4">
          <label>
            <input
              type="radio"
              name="mode"
              value="fixed"
              checked={mode === "fixed"}
              onChange={(e) => setMode(e.target.value)}
              className="mr-2"
            />
            Fixed Lot Size
          </label>
          <label>
            <input
              type="radio"
              name="mode"
              value="split"
              checked={mode === "split"}
              onChange={(e) => setMode(e.target.value)}
              className="mr-2"
            />
            Split Manually
          </label>
        </div>

        {mode === "fixed" && (
          <div className="mt-4">
            <label className="block font-medium mb-2">
              Lot Size
              <input
                type="number"
                value={lotSize}
                onChange={(e) => setLotSize(e.target.value)}
                className="block w-full p-2 border rounded mt-1"
              />
            </label>
            <Button variant="contained" color="primary" onClick={createFixedLots}>
              Create Lots
            </Button>
          </div>
        )}

        {mode === "split" && (
          <div className="mt-4">
            <p className="font-medium mb-2">Click "Split" to insert boundaries:</p>
            <div className="grid grid-cols-3 gap-4">
              {images.map((img, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(img)}
                    alt=""
                    className="w-[250px] h-[250px] object-cover border rounded"
                  />
                  {index < images.length - 1 && (
                    <Button
                      onClick={() => toggleSplit(index + 1)}
                      variant={splitIndices.includes(index + 1) ? "contained" : "outlined"}
                      color="error"
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                      }}
                    >
                      Split
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              onClick={createSplitLots}
            >
              Create Lots
            </Button>
          </div>
        )}
      </div>

      {lots.map((lot) => (
        <div key={lot.id} className="p-4 border border-gray-300 rounded mt-4 bg-white">
            <div className="flex justify-between">
          <h4 className="font-semibold">Lot {lot.id}</h4>
          <button
      onClick={() => setLots((prev) => prev.filter((l) => l.id !== lot.id))}
      className="text-red-600 "
    >
    <DeleteIcon/>
    </button>
    </div>
          <textarea
            value={lot.description}
            onChange={(e) =>
              setLots((prev) =>
                prev.map((l) => (l.id === lot.id ? { ...l, description: e.target.value } : l))
              )
            }
            placeholder="Lot Description"
            className="block w-full p-2 border border-gray-300 rounded mt-2"
          />
          <div className="grid grid-cols-3 gap-4 mt-4">
            {lot.images.map((img, i) => (
              <div key={i} className="relative">
                <img
                  src={URL.createObjectURL(img)}
                  alt=""
                  className="w-[250px] h-[250px] object-cover border border-gray-300 rounded"
                />
                <IconButton
                  onClick={() => {
                    const updatedLotImages = lot.images.filter((_, idx) => idx !== i);
                    setLots((prev) =>
                      prev.map((l) =>
                        l.id === lot.id ? { ...l, images: updatedLotImages } : l
                      )
                    );
                  }}
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 0, 0, 0.7)",
                    color: "white",
                    "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.9)" },
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      ))}

      {showCamera && (
        <CameraCapture onCapture={(file) => setImages((prev) => [...prev, file])} />
      )}
    </div>
  );
}