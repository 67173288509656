import React, { useState, useEffect } from "react";
import CameraCapture from "./CameraCapture";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { Stack, Typography, Button } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
export default function ManualLotCreator({ podName, currentTone, userId, lots: existingLots = [] }) {
    const [tone, setTone] = useState("");            // The *actual* tone text we send to the server
    const [lots, setLots] = useState([]);
    const [showCamera, setShowCamera] = useState(false);
    const [cameraLotId, setCameraLotId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentBatch, setCurrentBatch] = useState("");

    // Subscription details
    const [subscriptionId, setSubscriptionId] = useState("");

    // Store your customTone object here
    const [customToneObj, setCustomToneObj] = useState({});

    // We will derive the keys from customToneObj for the dropdown.
    const toneKeys = Object.keys(customToneObj);

    const podId = localStorage.getItem("podId") || podName;

    // Fetch user details (subscriptionId, customTone) on mount or when userId changes
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(
                    `https://v2backend-dot-listalot-440122.uc.r.appspot.com/get-user-details?userId=${localStorage.getItem("userId")}`
                );
                const { subscriptionId, customTone } = response.data;
                setSubscriptionId(subscriptionId || "");
                setCustomToneObj(customTone || {});
            } catch (error) {
                console.error("Failed to fetch user details:", error);
            }
        };
        fetchUserDetails();
    }, [userId]);

    useEffect(() => {
        if (existingLots.length > 0) {
            setLots(existingLots);
        } else {
            setLots([{ id: 1, description: "", images: [] }]);
        }
    }, [existingLots]);

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "JPEG",
                70,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    };

    const handleFileChange = async (e, lotId) => {
        const files = Array.from(e.target.files);
        if (!files.length) return;
        try {
            const resized = await Promise.all(files.map((file) => resizeImage(file)));
            setLots((prev) =>
                prev.map((lot) =>
                    lot.id === lotId ? { ...lot, images: [...lot.images, ...resized] } : lot
                )
            );
        } catch {
            alert("Failed to resize images.");
        }
    };

    const addLot = () => {
        const newId = lots.length + 1;
        setLots([...lots, { id: newId, description: "", images: [] }]);
    };

    const removeLot = (lotId) => {
        setLots((prev) => prev.filter((lot) => lot.id !== lotId));
    };

    const handleOpenCamera = (lotId) => {
        setCameraLotId(lotId);
        setShowCamera(true);
    };

    const handleCapture = (imageFile) => {
        if (!cameraLotId) return;
        setLots((prev) =>
            prev.map((lot) =>
                lot.id === cameraLotId ? { ...lot, images: [...lot.images, imageFile] } : lot
            )
        );
        setShowCamera(false);
        setCameraLotId(null);
    };

    const removeImageFromLot = (lotId, index) => {
        setLots((prev) =>
            prev.map((lot) => {
                if (lot.id === lotId) {
                    const updated = [...lot.images];
                    updated.splice(index, 1);
                    return { ...lot, images: updated };
                }
                return lot;
            })
        );
    };

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const uploadBatch = async (batch, batchIndex) => {
        try {
            const formData = new FormData();
            formData.append("podId", podId);
            formData.append("userId", userId);

            // We'll send the *entire text* of the chosen tone
            formData.append("tone", tone);

            formData.append("lots", JSON.stringify(batch));

            batch.forEach((lot) => {
                lot.images.forEach((img) => formData.append(`images_${lot.id}`, img));
            });

            const response = await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/update-pod", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (response.status === 200) {
                console.log(`Batch ${batchIndex + 1} updated successfully.`);
            }
        } catch (error) {
            console.error(`Error updating batch ${batchIndex + 1}:`, error.message);
            throw error;
        }
    };

    const handleUpdatePod = async () => {
        if (!lots.length) {
            alert("No lots to update.");
            return;
        }

        setLoading(true);
        setProgress(0);

        try {
            const batches = chunkArray(lots, 50);

            for (let i = 0; i < batches.length; i++) {
                setCurrentBatch(`Processing batch ${i + 1} of ${batches.length}`);
                await uploadBatch(batches[i], i);

                const progressPercentage = Math.round(((i + 1) / batches.length) * 100);
                setProgress(progressPercentage);
            }

            alert("All lots updated successfully.");
        } catch (error) {
            console.error("Error updating lots:", error.message);
            alert("Failed to update some lots. Check the console for details.");
        } finally {
            setLoading(false);
        }
    };

    const handleAnalyze = async () => {
        const payload = {
          podId: localStorage.getItem("podId"),
          lots,
          removeBg: false, // Default value
          tone,
          userId: localStorage.getItem("userId"),
        };
      
        try {
          const response = await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/finalize-analysis", payload, {
            headers: { "Content-Type": "application/json" },
          });
      
          if (response.status === 200) {
            alert("Analysis finalized successfully!");
          } else {
            alert("Failed to finalize analysis. Please try again.");
          }
        } catch (error) {
          console.error("Error during analysis:", error.message);
          alert("An error occurred during analysis. Check the console for details.");
        }
      };



    const areAllImagesStrings = lots.every((lot) =>
        lot.images.every((img) => typeof img === "string")
    );

    return (
        <div className="bg-gray-100 p-4 rounded shadow-md space-y-4">
            <h2 className="text-xl font-bold">Manual Lot Creator</h2>
            <div className="flex justify-center space-x-4">
                <button onClick={handleUpdatePod} className="px-4 py-2 bg-indigo-600 text-white rounded">
                    Update Pod
                </button>
                <button onClick={handleAnalyze} className={`px-4 py-2 rounded ${areAllImagesStrings
                        ? "bg-indigo-600 text-white"
                        : "bg-gray-400 text-gray-600 cursor-not-allowed"
                    }`}
                    disabled={!areAllImagesStrings}>
                    Analyze
                </button>
          
            </div>

            {/* Tone Selector */}
            <div>
                <label className="block font-medium mb-1">AI Tone</label>
                <select
                    value={

                        toneKeys.find((key) => customToneObj[key] === tone) || ""
                    }
                    onChange={(e) => {
                        const selectedKey = e.target.value;
                        const newTone = selectedKey ? customToneObj[selectedKey] : "";
                        setTone(newTone);
                    }}
                    className="p-2 w-full border border-gray-300 rounded"
                >
                    <option value="">Select Tone</option>
                    {toneKeys.map((key) => (
                        <option key={key} value={key}>
                            {key}
                        </option>
                    ))}
                </select>
            </div>

            {/* For debugging, you can show the chosen tone text */}
            <div>
                <label className="block font-medium">Selected New Tone Text:</label>
                <div className="p-2 bg-white border border-gray-300 rounded">
                    {tone || <em>No tone selected yet.</em>}
                </div>
            </div>
            <div>
                <label className="block font-medium">Previous Tone:</label>
                <div className="p-2 bg-white border border-gray-300 rounded">
                    {currentTone || ""}
                </div>
            </div>
            <div className="space-y-4">
                {lots.map((lot) => (
                    <div key={lot.id} className="border p-4 bg-white rounded shadow-sm">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="font-semibold">Lot #{lot.id}</h3>
                            <button onClick={() => removeLot(lot.id)} className="text-red-600 underline">
                                <DeleteIcon />
                            </button>
                        </div>
                        <label className="block font-medium mb-1">Lot Description</label>
                        <textarea
                            rows={3}
                            className="w-full p-2 border border-gray-300 rounded mb-3"
                            value={lot.description}
                            onChange={(e) => {
                                const desc = e.target.value;
                                setLots((prev) =>
                                    prev.map((l) => (l.id === lot.id ? { ...l, description: desc } : l))
                                );
                            }}
                        />
                        <Stack spacing={1} marginBottom={2}>
                            {/* Label */}
                            <Typography variant="subtitle1" fontWeight={500}>
                                Add Images
                            </Typography>


                            <Stack direction="row" spacing={2} alignItems="center">

                                <Button
                                    variant="outlined"
                                    startIcon={<UploadFileIcon />}
                                    component="label"
                                >
                                    Upload
                                    <input
                                        type="file"
                                        hidden
                                        multiple
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, lot.id)}
                                    />
                                </Button>

                                {/* Camera Button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CameraAltIcon />}
                                    onClick={() => handleOpenCamera(lot.id)}
                                >
                                    Camera
                                </Button>
                            </Stack>
                        </Stack>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {lot.images.map((img, i) => (
                                <div key={i} className="relative inline-block">
                                    {img instanceof File || img instanceof Blob ? (
                                        <img
                                            src={URL.createObjectURL(img)}
                                            alt=""
                                            className="w-[250px] h-[250px] object-cover border border-gray-300 rounded"
                                        />
                                    ) : (
                                        <img
                                            src={typeof img === "string" ? img : ""}
                                            alt=""
                                            className="w-[250px] h-[250px] object-cover border border-gray-300 rounded"
                                        />
                                    )}
                                    <IconButton
                                        onClick={() => removeImageFromLot(lot.id, i)}
                                        sx={{
                                            position: "absolute",
                                            top: 8,
                                            right: 8,
                                            backgroundColor: "rgba(255, 0, 0, 0.5)",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "rgba(255, 0, 0, 0.7)",
                                            },
                                            fontSize: "0.75rem",
                                        }}
                                        size="small"
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <button onClick={addLot} className="px-4 py-2 bg-blue-600 text-white rounded">
                    + Add New Lot
                </button>
            </div>

            {loading && (
                <div className="mt-4 bg-white p-4 rounded shadow">
                    <p className="text-center text-gray-600">{currentBatch}</p>
                    <p className="text-center text-gray-600">{progress}% Completed</p>
                    <div className="relative pt-1">
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                            <div
                                style={{ width: `${progress}%` }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                            ></div>
                        </div>
                    </div>
                </div>
            )}

            {showCamera && (
                <CameraCapture onCapture={handleCapture} onClose={() => setShowCamera(false)} />
            )}
        </div>
    );
}