import React, { useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

export default function Results({ lots, onUpdate, selectedPod }) {
  const [editedLots, setEditedLots] = useState(lots || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEditLot, setCurrentEditLot] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(""); // NEW for LN
  const [loading, setLoading] = useState(false);            // For button/submit states

  // ------------------------------------
  //  EDIT / DELETE logic from your code
  // ------------------------------------
  const handleEdit = (lot) => {
    setCurrentEditLot(JSON.parse(JSON.stringify(lot)));
    setModalOpen(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const podId = localStorage.getItem("podId");
      const userId = localStorage.getItem("userId");

      const updatedLots = editedLots.map((lot) =>
        lot.id === currentEditLot.id ? currentEditLot : lot
      );

      const formData = new FormData();
      formData.append("podId", podId);
      formData.append("userId", userId);
      formData.append("lots", JSON.stringify(updatedLots));

      await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/update-pod", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      onUpdate(updatedLots);
      setEditedLots(updatedLots);
      setModalOpen(false);
      alert("Lot updated successfully.");
    } catch (error) {
      console.error("Failed to update lot:", error.message);
      alert("Error updating the lot. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (lotId) => {
    try {
      setLoading(true);
      const updatedLots = editedLots.filter((lot) => lot.id !== lotId);

      const podId = localStorage.getItem("podId");
      const userId = localStorage.getItem("userId");

      const formData = new FormData();
      formData.append("podId", podId);
      formData.append("userId", userId);
      formData.append("lots", JSON.stringify(updatedLots));

      await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/update-pod", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      onUpdate(updatedLots);
      setEditedLots(updatedLots);
      alert("Lot deleted successfully.");
    } catch (error) {
      console.error("Failed to delete lot:", error.message);
      alert("Error deleting the lot. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // ------------------------------------
  //  SEND TO LN logic
  // ------------------------------------
  const handleSendToLN = async () => {
    try {
      if (!editedLots.length || !subscriptionId) {
        alert("Please ensure there are lots and enter a Subscription ID.");
        return;
      }
      setLoading(true);

      // Transform the lots into LN-friendly payload
      const transformedLots = editedLots.map((lot) => {
        return {
          lotIndex: lot.lotIndex || 0,
          description: lot.description || "",
          imageUrl: lot.images || [],
          results: {
            title: lot.analysis?.title || "",
            description: lot.analysis?.description?.replace(/\*/g, "") || "",
            price: lot.analysis?.price || 0,
            category: lot.analysis?.category || "",
            weight: lot.analysis?.weight || "",
            dimensions: lot.analysis?.dimensions
              ? {
                  height: lot.analysis.dimensions.height || "",
                  length: lot.analysis.dimensions.length || "",
                  width: lot.analysis.dimensions.width || "",
                }
              : { height: "", length: "", width: "" },
            quantity: lot.analysis?.quantity || 1,
            mpn: lot.analysis?.mpn || "",
            model: lot.analysis?.model || "",
            manufacturer: lot.analysis?.manufacturer || "",
            brand: lot.analysis?.brand || "",
            color: lot.analysis?.color || "",
            gender: lot.analysis?.gender || "",
            pattern: lot.analysis?.pattern || "",
            size: lot.analysis?.size || "",
            condition: lot.analysis?.condition || "",
            upc: lot.analysis?.upc || "",
            asin: lot.analysis?.asin || "",
            original: lot.original || [],
            noBackground: lot.noBackground || [],
            alternative_categories:
              lot.analysis?.alternative_categories || [],
            alternative_prices: lot.analysis?.alternative_prices || {},
            specifications: lot.analysis?.specifications || {},
            similar_items: lot.analysis?.similar_items || [],
          },
        };
      });

      const payload = {
        PodName: localStorage.getItem("podId") || "default_pod",
        ApiKey: "q0wY5liJaudGSTeF8e94vtBh2f83",
        lalPayload: transformedLots,
        SubscriptionId: subscriptionId,
      };

      console.log("Sending LN payload:", payload);

      const response = await axios.post(
        "https://listernautapi.azurewebsites.net/api/v2/listalot/import",
        payload
      );
      alert("Lots sent successfully to LN!");
      console.log("Response from LN:", response.data);
      const formData = new FormData();
      formData.append("podId",  localStorage.getItem("podId"));
      formData.append("userId", localStorage.getItem("userId"));
      formData.append("sentToLN", true);
      formData.append("lots", JSON.stringify(editedLots));
      await axios.post("https://v2backend-dot-listalot-440122.uc.r.appspot.com/update-pod", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      // Refresh local state
      onUpdate((prevLots) =>
        prevLots.map((lot) => ({
          ...lot,
          sentToLN: true, // Reflect the updated flag locally
        }))
      );
  
      // Close the LN dialog
      setModalOpen(false);
    } catch (error) {
      console.error("Error sending lots to LN:", error.message);
      if (error.response && error.response.data) {
        console.error("API response data:", error.response.data);
      }
      alert("Failed to send lots to LN.");
    } finally {
      setLoading(false);
    }
  };
// Handles updates for array fields (e.g., comma-separated input)
const handleArrayFieldChange = (field, value) => {
    const array = value.split(",").map((item) => item.trim());
    setCurrentEditLot((prev) => ({
      ...prev,
      analysis: {
        ...prev.analysis,
        [field]: array,
      },
    }));
  };
  
  // Handles updates for object fields (e.g., JSON input)
  const handleObjectFieldChange = (field, value) => {
    let obj;
    try {
      obj = JSON.parse(value); // Parse JSON input
    } catch (e) {
      console.warn("Invalid JSON:", value); // Warn if JSON is invalid
      obj = {}; // Default to empty object
    }
    setCurrentEditLot((prev) => ({
      ...prev,
      analysis: {
        ...prev.analysis,
        [field]: obj,
      },
    }));
  };
  // For nested 'analysis' edits in the modal (optional if you still want it)
  const handleNestedChange = (key, subKey, value) => {
    setCurrentEditLot((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [subKey]: value,
      },
    }));
  };

  // ------------------------------------
  // RENDER
  // ------------------------------------
  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Analysis Results</h2>

      {/* Buttons row */}
      <div className="flex items-center justify-end space-x-2 mb-4">
        {/* SEND TO LN button (open LN dialog) */}
        <Button
          variant="contained"
          color="success"
          onClick={() => setModalOpen(true)}
          disabled={loading || !editedLots.length}
        >
          Send to LN
        </Button>
      </div>

      {/* TABLE with limited columns */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Lot ID</th>
            <th className="border border-gray-300 px-4 py-2">Title</th>
            <th className="border border-gray-300 px-4 py-2">Images</th>
            <th className="border border-gray-300 px-4 py-2">Price</th>
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {editedLots.map((lot) => (
            <tr key={lot.id}>
              <td className="border border-gray-300 px-4 py-2">{lot.id}</td>
              <td className="border border-gray-300 px-4 py-2">
                {lot.analysis?.title || "N/A"}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <div className="flex flex-wrap gap-2">
                  {lot.images?.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Lot ${lot.id} - ${index}`}
                      className="w-16 h-16 object-cover rounded border"
                    />
                  ))}
                </div>
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {`$${lot.analysis?.price || "0"}`}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {lot.analysis?.category || "N/A"}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <IconButton onClick={() => handleEdit(lot)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(lot.id)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/*
        ======================================
        DIALOG for BOTH "Edit" AND "Send to LN" 
        If you want separate modals for edit vs LN, 
        you can manage them with separate states. 
        Here, we do a separate <Dialog> for LN:
        ======================================
      */}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Send to LN</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Subscription ID"
            value={subscriptionId}
            onChange={(e) => setSubscriptionId(e.target.value)}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSendToLN} color="primary" disabled={loading}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* --- MODAL for editing all fields in the 'analysis' object --- */}
      { currentEditLot && (
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Lot {currentEditLot.id}</DialogTitle>
          <DialogContent>

            {/* Title */}
            <TextField
              fullWidth
              margin="normal"
              label="Title"
              value={currentEditLot.analysis?.title || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "title", e.target.value)
              }
            />

            {/* Description */}
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              multiline
              value={currentEditLot.analysis?.description || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "description", e.target.value)
              }
            />

            {/* Price */}
            <TextField
              fullWidth
              margin="normal"
              label="Price"
              type="number"
              value={currentEditLot.analysis?.price || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "price", e.target.value)
              }
            />

            {/* Category */}
            <TextField
              fullWidth
              margin="normal"
              label="Category"
              value={currentEditLot.analysis?.category || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "category", e.target.value)
              }
            />

            {/* Weight */}
            <TextField
              fullWidth
              margin="normal"
              label="Weight"
              value={currentEditLot.analysis?.weight || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "weight", e.target.value)
              }
            />

            {/* Dimensions */}
            <TextField
              fullWidth
              margin="normal"
              label="Dimensions (Width)"
              value={currentEditLot.analysis?.dimensions?.width || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "dimensions", {
                  ...currentEditLot.analysis.dimensions,
                  width: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Dimensions (Height)"
              value={currentEditLot.analysis?.dimensions?.height || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "dimensions", {
                  ...currentEditLot.analysis.dimensions,
                  height: e.target.value,
                })
              }
            />

            {/* Quantity */}
            <TextField
              fullWidth
              margin="normal"
              label="Quantity"
              type="number"
              value={currentEditLot.analysis?.quantity || 1}
              onChange={(e) =>
                handleNestedChange("analysis", "quantity", e.target.value)
              }
            />

            {/* MPN */}
            <TextField
              fullWidth
              margin="normal"
              label="MPN"
              value={currentEditLot.analysis?.mpn || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "mpn", e.target.value)
              }
            />

            {/* Model */}
            <TextField
              fullWidth
              margin="normal"
              label="Model"
              value={currentEditLot.analysis?.model || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "model", e.target.value)
              }
            />

            {/* Manufacturer */}
            <TextField
              fullWidth
              margin="normal"
              label="Manufacturer"
              value={currentEditLot.analysis?.manufacturer || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "manufacturer", e.target.value)
              }
            />

            {/* Brand */}
            <TextField
              fullWidth
              margin="normal"
              label="Brand"
              value={currentEditLot.analysis?.brand || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "brand", e.target.value)
              }
            />

            {/* Color */}
            <TextField
              fullWidth
              margin="normal"
              label="Color"
              value={currentEditLot.analysis?.color || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "color", e.target.value)
              }
            />

            {/* Gender */}
            <TextField
              fullWidth
              margin="normal"
              label="Gender"
              value={currentEditLot.analysis?.gender || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "gender", e.target.value)
              }
            />

            {/* Pattern */}
            <TextField
              fullWidth
              margin="normal"
              label="Pattern"
              value={currentEditLot.analysis?.pattern || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "pattern", e.target.value)
              }
            />

            {/* Size */}
            <TextField
              fullWidth
              margin="normal"
              label="Size"
              value={currentEditLot.analysis?.size || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "size", e.target.value)
              }
            />

            {/* Condition */}
            <TextField
              fullWidth
              margin="normal"
              label="Condition"
              value={currentEditLot.analysis?.condition || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "condition", e.target.value)
              }
            />

            {/* UPC */}
            <TextField
              fullWidth
              margin="normal"
              label="UPC"
              value={currentEditLot.analysis?.upc || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "upc", e.target.value)
              }
            />

            {/* ASIN */}
            <TextField
              fullWidth
              margin="normal"
              label="ASIN"
              value={currentEditLot.analysis?.asin || ""}
              onChange={(e) =>
                handleNestedChange("analysis", "asin", e.target.value)
              }
            />

            {/* alternative_categories (array) */}
            <TextField
              fullWidth
              margin="normal"
              label="Alternative Categories (comma-separated)"
              value={
                (currentEditLot.analysis?.alternative_categories || []).join(", ")
              }
              onChange={(e) =>
                handleArrayFieldChange("alternative_categories", e.target.value)
              }
            />

            {/* similar_items (array) */}
            <TextField
              fullWidth
              margin="normal"
              label="Similar Items (comma-separated)"
              value={(currentEditLot.analysis?.similar_items || []).join(", ")}
              onChange={(e) =>
                handleArrayFieldChange("similar_items", e.target.value)
              }
            />

            {/* alternative_prices (object) */}
            <TextField
              fullWidth
              margin="normal"
              multiline
              label="Alternative Prices (JSON)"
              value={
                currentEditLot.analysis?.alternative_prices
                  ? JSON.stringify(currentEditLot.analysis.alternative_prices)
                  : ""
              }
              onChange={(e) =>
                handleObjectFieldChange("alternative_prices", e.target.value)
              }
            />

            {/* specifications (object) */}
            <TextField
              fullWidth
              margin="normal"
              multiline
              label="Specifications (JSON)"
              value={
                currentEditLot.analysis?.specifications
                  ? JSON.stringify(currentEditLot.analysis.specifications)
                  : ""
              }
              onChange={(e) =>
                handleObjectFieldChange("specifications", e.target.value)
              }
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}