import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import logo from "./assets/logo.png";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

// Simple styled wrappers
const SidebarContainer = styled("div")(() => ({
  display: "flex",
}));

const SidebarHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  // You can add more styling (like a background color) if desired
}));

const Sidebar = ({ setSelectedComponent }) => {
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  // Load the previously selected component from localStorage (if any)
  useEffect(() => {
    const stored = localStorage.getItem("selectedComponent");
    if (stored) {
      setSelected(stored);
      setSelectedComponent(stored);
    } 
  }, [setSelectedComponent]);

  // Handle selection and persist it
  const handleSelect = (component) => {
    setSelected(component);
    setSelectedComponent(component);
    localStorage.setItem("selectedComponent", component);
  };

  // Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <SidebarContainer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <SidebarHeader>
          {/* Logo and App Name */}
          <img
            src={logo}
            alt="Logo"
            style={{ width: 40, height: 40, borderRadius: "50%", marginRight: 8 }}
          />
          <Typography variant="h6" noWrap>
            Listalot
          </Typography>
        </SidebarHeader>

        {/* Side Navigation Links */}
        <List>
          <ListItem disablePadding>
            <ListItemButton
              selected={selected === "CSVUploader"}
              onClick={() => handleSelect("CSVUploader")}
            >
              <ListItemText primary="CSV Uploader" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={selected === "CreatePods"}
              onClick={() => handleSelect("CreatePods")}
            >
              <ListItemText primary="Create Pod" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={selected === "BarcodeLookup"}
              onClick={() => handleSelect("BarcodeLookup")}
            >
              <ListItemText primary="Barcode Lookup" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={selected === "UserSettings"}
              onClick={() => handleSelect("UserSettings")}
            >
              <ListItemText primary="User Settings" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton
              selected={selected === "Payments"}
              onClick={() => handleSelect("Payments")}
            >
              <ListItemText primary="Payments" />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </SidebarContainer>
  );
};

export default Sidebar;