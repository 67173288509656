import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";

const UserSettings = () => {
  const [subscriptionId, setSubscriptionId] = useState("");
  const [customTone, setCustomTone] = useState({});
  const [selectedToneKey, setSelectedToneKey] = useState("");
  const [newToneKey, setNewToneKey] = useState("");
  const [newToneValue, setNewToneValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`https://devbackend.listornot.com/get-user-details?userId=${userId}`);
      const { subscriptionId, customTone } = response.data;
      setSubscriptionId(subscriptionId || "");
      setCustomTone(customTone || {});
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      alert("Failed to fetch user details.");
    } finally {
      setIsFetching(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const payload = {
        userId,
        subscriptionId,
        customTone,
      };
      await axios.post("https://devbackend.listornot.com/update-user-settings", payload);
      alert("User details updated successfully.");
    } catch (error) {
      console.error("Error updating user details:", error.message);
      alert("Failed to update user details.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrUpdateTone = () => {
    if (newToneKey && newToneValue) {
      setCustomTone({
        ...customTone,
        [newToneKey]: newToneValue,
      });
      setNewToneKey("");
      setNewToneValue("");
      setSelectedToneKey(""); // Clear the selection
    } else if (selectedToneKey && newToneValue) {
      setCustomTone({
        ...customTone,
        [selectedToneKey]: newToneValue,
      });
      setNewToneValue("");
    }
  };

  const handleDeleteTone = (key) => {
    const updatedTone = { ...customTone };
    delete updatedTone[key];
    setCustomTone(updatedTone);
    setSelectedToneKey(""); // Clear the selection
    setNewToneKey("");
    setNewToneValue("");
  };

  const handleToneSelection = (key) => {
    setSelectedToneKey(key);
    setNewToneKey(key);
    setNewToneValue(customTone[key] || "");
  };

  if (isFetching) {
    return (
      <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
        Loading user data...
      </Typography>
    );
  }

  return (
    <Box className="p-4 bg-white rounded shadow-md space-y-4">
      <Typography variant="h4" style={{ color: "#3f51b5", textAlign: "center" }}>
        User Settings
      </Typography>

      {/* Subscription ID */}
      <TextField
        fullWidth
        label="Subscription ID"
        value={subscriptionId}
        onChange={(e) => setSubscriptionId(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      {/* Custom Tone Management */}
      <Box mt={4}>
        <Typography variant="h6">Manage Custom Tone</Typography>
        <Select
          fullWidth
          value={selectedToneKey}
          onChange={(e) => handleToneSelection(e.target.value)}
          displayEmpty
          style={{ marginTop: "16px" }}
        >
          <MenuItem value="">
            <em>Select Existing Tone to Update</em>
          </MenuItem>
          {Object.keys(customTone).map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>

        <TextField
          fullWidth
          label="Tone Key"
          value={newToneKey}
          onChange={(e) => setNewToneKey(e.target.value)}
          variant="outlined"
          margin="normal"
          placeholder="Add New Tone Key"
        />
        <TextField
          fullWidth
          label="Tone Value"
          value={newToneValue}
          onChange={(e) => setNewToneValue(e.target.value)}
          variant="outlined"
          margin="normal"
          placeholder="Enter Tone Description"
        />
        <Box mt={2} display="flex" gap={2}>
          <Button variant="contained" color="primary" onClick={handleAddOrUpdateTone}>
            {selectedToneKey ? "Update Tone" : "Add Tone"}
          </Button>
          {selectedToneKey && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeleteTone(selectedToneKey)}
            >
              Delete Tone
            </Button>
          )}
        </Box>
      </Box>

      {/* Save Changes */}
      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleUpdate} disabled={loading}>
          {loading ? "Updating..." : "Save Changes"}
        </Button>
      </Box>
    </Box>
  );
};

export default UserSettings;